* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.wrapper {
  max-width: 1000px;
  margin: 0 auto;
}
body {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 2;
  background: rgb(255, 255, 255);
  color: black;
}
button {
  background-color: rgb(34, 9, 124);
  color: white;
  display: block;
  width: 90%;
  padding: 10px;
  margin: 10px auto;
  outline: none;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
}

button:hover {
  background-color: rgb(88, 7, 7);
}

.time {
  /* padding: 10px 0; */
  color: black;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
}

.time span:nth-child(1) {
  width: 55%;
  line-height: 14px;
}
.time div:nth-child(2) {
  width: 25%;
}
/* .time button {
} */
.cancel {
  width: 100%;
  background-color: red;
  text-align: center;
  font-size: 10px;
  color: aliceblue;
  border-radius: 5px;
}

.btnGroups {
  width: 90%;
  margin: 15px auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  border: 1px solid black;
  padding: 0;
}
.btnGroups button {
  background-color: white;
  border: 1px solid black;
  color: black;
  padding: 9px 5px;
  font-size: 10px;
  margin: 0;
}
.btnGroups button:hover {
  background-color: #777;
  color: aliceblue;
}

.btnGroups .active {
  background-color: rgb(84, 79, 79);
  color: aliceblue;
}

.select {
  width: 90%;
  margin: 10px auto;
  margin-bottom: 5px;
}
.select select {
  width: 100%;
  margin: 0 auto;
  padding: 10px;
}

.edit {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  position: fixed;
  top: 85px;
  right: 13px;
  /* background-color: white; */
  z-index: 10;
  width: 70%;
}
.edit button {
  width: 34%;
  font-size: 12px;
  padding: 9px 7px;
}
.edit select {
  padding: 7px;
  background-color: white;
}
.cancel:hover {
  background-color: rgb(104, 13, 13);
}

/* .rotateC {
  animation: rotate;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-duration: 2s;
  background-color: blue;
} */
.btn {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
}
.welcome {
  background-color: rgb(16, 54, 86);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 20;
}

h1 {
  font-size: 1.6em;
  padding: 20px 0;
  text-align: center;
  color: white;
  text-transform: uppercase;
}

.info {
  width: 90%;
  margin: auto;
  max-width: 700px;
}
.total {
  text-align: center;
}
.box {
  margin-bottom: 30px;
  padding: 20px 0 10px 10px;
  width: 100%;
  margin: auto;
  opacity: 0.5;
  word-break: break-all;
  /* display: flex; */
}

.box span {
  /* color: rgba(119, 119, 119, 0.5); */
  opacity: 1;
}

.card {
  width: 100%;
  margin: 50px auto;
  box-shadow: 2px 2px 10px #777;
  border-radius: 7px;
  padding: 10px;
  /* margin-bottom: 30px; */
}
.nodata {
  text-align: center;
  margin: 30px 0;
}
@media (min-width: 670px) {
  .btn {
    padding: 20px 0;
    display: flex;
    flex-direction: row;
  }
  button {
    width: 40%;
  }
  .card {
    width: 100%;
    margin: 0 auto;
    box-shadow: 2px 2px 10px #777;
    border-radius: 7px;
    padding: 10px;
  }
  .info {
    width: 98%;
    margin: auto;
  }
  .box {
    margin-bottom: 30px;

    padding: 20px 0 10px 10px;
    width: 100%;
    max-width: 100%;
  }
}
